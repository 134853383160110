<template>
  <b-container>
    <b-row>
      <b-col cols="12" class="mt-4">
        <b-card class="mb-2" bg-variant="dark" text-variant="white">
          <h1>Reports</h1>
        </b-card>
      </b-col>
      <b-col cols="12" class="mt-4">
        <b-card class="mb-2" bg-variant="dark" text-variant="white">
          <b-row class="mb-4">
            <b-col cols="12" md="6">
              <b-card-title>Mint totals</b-card-title>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-select
                v-model="selectedMonth"
                :options="months"
              ></b-form-select>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-input
                v-model="filter"
                placeholder="Search"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-card-text v-if="totals">
            Mints: {{ totals.mints }}<br />
            Refunds: {{ totals.refunds }}<br />
            Collects Total: {{ totals.collectsTotal }}<br />
            <b>Service Amount: ₳{{ totals.serviceAmt | lovelace }}</b>
          </b-card-text>
          <template v-if="!loading">
            <BarChart v-if="stats.length" :chartData="chartData" />
            <b-table
              v-if="stats.length"
              dark
              striped
              :fields="tableFields"
              :filter="filter"
              :items="stats"
              responsive
            >
            </b-table>
            <b-alert v-else show> No results found </b-alert>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import { orderBy, groupBy, forEach, sumBy, uniq } from "lodash";
import { db } from "@/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import BarChart from "@/components/BarChart";
// import LineChart from "@/components/LineChart";

export default {
  name: "Reports",
  components: {
    BarChart,
  },
  data() {
    return {
      loading: true,
      filter: "",
      stats: [],
      tableFields: [
        "date",
        "client",
        "collectionId",
        "refundCount",
        "serviceAmt",
        "mintCount",
        "bundleCount",
        "collectCount"
      ],
      selectedMonth: "",
      months: [],
      totals: null,
      chartData: {},
    };
  },
  created() {
    const isAdmin = this.$store.getters.isAdmin;
    if (!isAdmin) {
      this.$router.push({ name: "ConsoleHome" });
    }

    const firstMonth = "2021-12";
    const currentMonth = moment().format("YYYY-MM");
    this.selectedMonth = currentMonth;
    const diff = moment(currentMonth).diff(moment(firstMonth), "months");
    let months = [];
    for (let index = diff; index >= 0; index--) {
      const month = moment(firstMonth).add(index, "months").format("YYYY-MM");
      months.push(month);
    }
    this.months = months;

    this.loadData();
  },
  methods: {
    async loadData() {
      this.stats = [];
      this.totals = null;
      this.loading = true;

      const q = query(
        collection(db, "stats"),
        where("month", "==", this.selectedMonth)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size) {
        let results = [];
        let clients = [];
        let totals = {
          mints: 0,
          refunds: 0,
          serviceAmt: 0,
          collectsTotal: 0
        };
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          totals.mints += data.mintCount;
          if(data.bundleCount) {
            totals.mints += data.bundleCount
          }
          totals.refunds += data.refundCount;
          totals.serviceAmt += data.serviceAmt;
          if (data.collectCount) {
            totals.collectsTotal += data.collectCount;
          }
          results.push(data);
          clients.push(data.client);
        });
        this.stats = orderBy(results, "date");
        clients = uniq(clients);
        let chartData = {
          labels: [],
          datasets: [{}],
        };
        let dataMintCount = [];
        let dataServiceAmt = [];
        let dataCollectCount = [];
        let dataBundleCount = []

        forEach(groupBy(this.stats, "date"), (values, key) => {
          const sumMintCount = sumBy(values, "mintCount");
          const sumServiceAmt = sumBy(values, "serviceAmt");
          const sumCollectsCount = sumBy(values, "collectCount");
          const sumBundleCount = sumBy(values, "bundleCount");
          chartData.labels.push(key);
          dataMintCount.push(sumMintCount);
          dataServiceAmt.push(sumServiceAmt / 1000000);
          dataCollectCount.push(sumCollectsCount);
          dataBundleCount.push(sumBundleCount);
        });

        chartData.datasets[0] = {
          label: 'Mint Count',
          maxBarThickness: 10,
          categoryPercentage: 0.4,
          backgroundColor: "#4b38b3",
          borderColor: "#4b38b3",
          data: dataMintCount,
        };
        chartData.datasets[1] = {
          label: 'Revenue',
          maxBarThickness: 10,
          categoryPercentage: 0.4,
          backgroundColor: "#86DC3D",
          borderColor: "#86DC3D",
          data: dataServiceAmt,
        };
        chartData.datasets[2] = {
          label: 'Collects Total',
          maxBarThickness: 10,
          categoryPercentage: 0.4,
          backgroundColor: "#e84118",
          borderColor: "#e84118",
          data: dataCollectCount,
        };
        chartData.datasets[3] = {
          label: 'Bundle Count',
          maxBarThickness: 10,
          categoryPercentage: 0.4,
          backgroundColor: "#fbc531",
          borderColor: "#fbc531",
          data: dataBundleCount,
        };
        this.chartData = chartData;
        this.totals = totals;

      }
      this.loading = false;
    },
  },
  watch: {
    selectedMonth() {
      this.loadData();
    },
  },
  filters: {
    lovelace(price) {
      return price / 1000000;
    },
  },
};
</script>

<style></style>
