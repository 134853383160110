<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    chartData: Object,
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        // height: 325,
        maintainAspectRatio: false,
        legend: {
          display: 'top',
        },
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
  watch: {
    chartData() {
      this._data._chart.destroy();
      this.renderChart(this.chartData, this.chartOptions);
    },
  },
};
</script>
